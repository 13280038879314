/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

	// Use this variable to set up the common and page specific functions. If you
	// rename this variable, you will also need to rename the namespace below.
	var Sage = {
		// All pages
		'common': {
			init: function() {
				// JavaScript to be fired on all pages
				jQuery('.flexslider').flexslider({
					// controlsContainer: $(".custom-controls-container"),
					// customDirectionNav: $(".custom-navigation a"),     
					animation: "slide",
    				controlNav: "thumbnails",
					prevText: '<img src="/wp-content/themes/foodbox/dist/images/left.png">',
    				nextText: '<img src="/wp-content/themes/foodbox/dist/images/right.png">',
    				slideshowSpeed: 200000,
				});

				$('.collapse-google').on('shown.bs.collapse', function(){
					var hash = $(this).attr('id');
					hash = '#' + hash;
					var offsetPixels = $(hash).offset().top;
					$("html, body").animate({ scrollTop: offsetPixels  }, "slow");

					
				});

				$('#menu-item-470').on('click', function(){
					var hash = window.location.hash;
					console.log(hash);
					var offsetPixels = $(hash).offset().top;
					$("html, body").animate({ scrollTop: offsetPixels  }, "slow");
				});

				$('.fancybox').fancybox({minWidth: 260,  helpers: {
						overlay: {
							locked: false
						}
					}
				});
			},
			finalize: function() {
				// JavaScript to be fired on all pages, after page specific JS is fired
			}
		},
		// Home page
		'home': {
			init: function() {
				// JavaScript to be fired on the home page
			},
			finalize: function() {
				// JavaScript to be fired on the home page, after the init JS
			}
		},
		// About us page, note the change from about-us to about_us.
		'about_us': {
			init: function() {
				// JavaScript to be fired on the about us page
			}
		}
	};

	// The routing fires all common scripts, followed by the page specific scripts.
	// Add additional events for more control over timing e.g. a finalize event
	var UTIL = {
		fire: function(func, funcname, args) {
			var fire;
			var namespace = Sage;
			funcname = (funcname === undefined) ? 'init' : funcname;
			fire = func !== '';
			fire = fire && namespace[func];
			fire = fire && typeof namespace[func][funcname] === 'function';

			if (fire) {
				namespace[func][funcname](args);
			}
		},
		loadEvents: function() {
			// Fire common init JS
			UTIL.fire('common');

			// Fire page-specific init JS, and then finalize JS
			$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
				UTIL.fire(classnm);
				UTIL.fire(classnm, 'finalize');
			});

			// Fire common finalize JS
			UTIL.fire('common', 'finalize');
		}
	};

	// Load Events
	$(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
